<template>
  <el-main class="rh-form">
    <form-thesecreate :routes="thRoutes" />
  </el-main>
</template>
<script>
import FormThesecreate from "@/components/rh/formulaires/FormThesecreate";

export default {
  components: { "form-thesecreate": FormThesecreate },
  computed: {
    thRoutes() {
      return {
        toedit: {
          name: "theseedit",
          params: { idth: this.$store.state.th.theseLastCreatedId },
        },
        tolist: { name: "theses" },
      };
    },
  },
};
</script>
